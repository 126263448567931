.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}
.marg{
    width: 100%;
    padding: 1rem;
}
.boton{
    float:right;
    margin: 10px;
}
.hr2{
    border-radius: 10px;
    background-color: lightblue;
    border: solid black;
   }



.SearchUser:hover span{
  background-color: black !important; 
  color: blue !important;
}

#span1:hover{color:red;}
#span1{color:blue;}
    .price-box:hover span {
       color: green !important;
    }