.wrapper {
    display: block;
    width: 100%;
    align-items: stretch;
    
}
#sidebar {
    min-width: 250px;
    max-width: fit-content;
    min-height: 100vh;
}

#sidebar.active {
    margin-left: -250px;
}
a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}
@media (max-width: 768px) {
    #sidebar {
        margin-left: -250px;
    }
    #sidebar.active {
        margin-left: 0;
    }
}
@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";


body {
    font-family: 'Poppins', sans-serif;
    background: #fefefe;
}

p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}

a, a:hover, a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

#sidebar {
    /* don't forget to add all the previously mentioned styles here too */
    background: #242c37;
    color: #fff;
    transition: all 0.3s;
}

#sidebar .sidebar-header {
    padding: 20px;
    background: #242c37;
}

#sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #242c37;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
}
#sidebar ul li a:hover {
    color: #02275e;
    background: #fff;
}

#sidebar ul li.active > a, a[aria-expanded="true"] {
    color: #fff;
    background: #02275e;
}
ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #02275e;
}
a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}
.Logo {
    width: 200px;
    height: 100px;
  }

.button-pos {
    margin: 60px;

}
.bottom-div{

    bottom: 0px;
}

.buttonGenerar{
    margin-top: 5%;
    display: block !important;
    align-content: center;
    align-items: center;
    left: 37%;
    position: absolute;
}


/* 
.parent {
    text-align: center;
    width: 40%;
  }
  .child {
    display: inline-block;
    border: 1px solid red;
    padding: 1rem 1rem;
    vertical-align: middle;
  } */
  #collum {
    position: absolute;
    width: 62%;
    height: 100%;
}
.blocks {
    display: inline-block;
    width: 49%;
    text-align: center;
}